import React, {useState} from "react";
import './Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../images/logo.png';
import burgerbar from '../images/burgerbar.png';
import cross from '../images/cross.png';

const Navbar = () => {
  const [click, setClick] = useState(false);

  const handleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(false);

  return (
    <>
      <nav className="navbar">
        <Link to='/' className="navbar-logo-link">
          <img className="navbar-logo" src={logo} alt="Logo"/>
        </Link>
          {!click ? <img onClick={handleClick} className='menu-icon' src={burgerbar} alt="Burger menu icon"/> : <img onClick={handleClick} className='menu-icon' src={cross} alt="Close icon"/> }
        <ul className={click ? 'nav-menu active' : 'nav-menu'}>
          <li className='nav-item'>
            <Link to='/' className='nav-links' onClick={closeMobileMenu}>
              Home
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/about' className='nav-links' onClick={closeMobileMenu}>
              About
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/game' className='nav-links' onClick={closeMobileMenu}>
              Game
            </Link>
          </li>
          <li className='nav-item'>
            <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </>
  )
};
export default Navbar;