import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom' 
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import About from './components/pages/About';
import Game from './components/pages/Game';
import Contact from './components/pages/Contact';
import Footer from './components/Footer';

function App() {
  return (
   <BrowserRouter>
    <Navbar/>
    <Routes>
      <Route path="/" element={<Home/>}/>
      <Route path="/about" element={<About/>}/>
      <Route path="/game" element={<Game/>}/>
      <Route path="/contact" element={<Contact/>}/>
    </Routes>
    <Footer/>
   </BrowserRouter>
  );
}

export default App;
