import React from "react";
import './game.css'
import kickstarter from '../../images/kickstarter.png';
import game_intro from '../../images/games_intro.png';
import game_characters from '../../images/games_characters.png';
import game_board from '../../images/games_board.png';
import game_start from '../../images/games_start.png';
import game_turnorder from '../../images/games_turnorder.png';
import move from '../../images/move.gif';
import game_move from '../../images/games_move.png';
import game_encounter from '../../images/games_encounter.png';
import encounter from '../../images/encounter.gif';
import game_forest_tiles from '../../images/games_forest_tiles.png';
import game_encounter_cards from '../../images/games_encounter_cards.png';
import game_interact from '../../images/games_interact.png';
import game_location_tiles from '../../images/games_location_tiles.png';
import game_standoff from '../../images/games_standoff.png';
import game_win from '../../images/games_win.png';
import game_win_example from '../../images/games_win_example.png';
import belt from '../../images/belt.png'
import MailChimpForm from "components/MailchimpForm";

const Game = () => {
  return(
    <div className="game">
      <div className="game-wrapper">
        <section className="upcoming">
          <div className="upcoming-intro">
            <p>Coming to </p>
            <a href="https://www.kickstarter.com/projects/sbpublishing/the-forest-of-fyri-perilous-pursuit-0">
              <img className="game_kickstarter" src={kickstarter} alt="kickstarter logo" />
            </a>
          </div>
          <p className="upcoming-intro-spring">Late Autumn 2023</p>
          <img className="intro-img" src={game_intro} alt="Logo and characters from the game"/>
          <img className="intro-img" src={game_characters} alt="Characters from the game"/>
          <img className="intro-img" src={game_board} alt="The game from above"/>
        </section>
          <p className="how-to-heading">How to play</p>
          <img className="game-belt" src={belt} alt="ornate belt" />
        <section className="cards-section">
          <div className="card">
            <img className="card-img" src={game_start} alt="start text"/>
            <img className="card-img" src={game_turnorder} alt="Turn order displayed on the gameboard"/>
            <div className="card-text">
              <p>
                To start the game, whoever <span className="emphasized-text">"visited a forest most recently" </span>
                chooses a Champion first. 
                Then, at the start of every round, lay out the Champion tokens 
                in random order to decide the turn order.
              </p>
            </div>
          </div>
          <div className="card">
            <img className="card-img" src={game_move} alt="Move text"/>
            <img className="card-gif" src={move} alt="How to move"/>
            <div className="card-text">
              <p>
                A Champion will exit the tile through a matching rune onto the next tile. 
                Any tile with no Champion standing on it will be discarded, acting as the forest 
                path disappearing behind them. (Campfire and town tiles not subjected to this rule).
              </p>
            </div>
          </div>
          <div className="card">
          <img className="card-img" src={game_encounter} alt="Encounter text"/>
            <img className="card-gif" src={encounter} alt="Encounter"/>
            <div className="card-text">
              <p>
                When the revealed tile is a forest, flip the top card of the Forest Encounter deck 
                and fight the monster accordingly. Make sure to remember your passive and 
                active skills, as well as items to win the combat!
              </p>
            </div>
            <img className="card-img" src={game_forest_tiles} alt="Example of forest tiles"/>
            <img className="card-img" src={game_encounter_cards} alt="Example of encounter  cards"/>
          </div>
          <div className="card">
            <img className="card-img" src={game_interact} alt="Interact text"/>
            <img className="card-img" src={game_location_tiles} alt="Exampel of location tiles"/>
            <div className="card-text">
              <p>
                When the revealed tile is not a forest but a Location tile, no Forest Encounter cards 
                need to be flipped. The Champion can interact with the tile, or instead, use this phase 
                to move another step and proceed to Encounter/Interact on the next tile.
              </p>
            </div>
            <img className="card-img" src={game_standoff} alt="Exampel of standoff"/>
            <div className="card-text">
              <p>
                A Champion can also jump over to an adjoined tile occupied by 
                another Champion and declare a duel for a bounty. 
              </p>
            </div>
          </div>
          <div className="last-card">
            <div className="card">
              <img className="card-img" src={game_win} alt="Win text"/>
              <img className="card-img" src={game_win_example} alt="How to win"/>
              <div className="card-text">
                <p>
                  To win the game, players need to find their way into town and prove their worth 
                  by handing in bounties in exchange for victory points. The game is over 
                  when a Champion reaches a predetermined amount of victory points. 
                </p>
              </div>
            </div>
          </div>
        </section>
        <img className="game-belt" src={belt} alt="ornate belt" />
        <p className="sign-up">Sign up on</p>
        <a href="https://www.kickstarter.com/projects/sbpublishing/the-forest-of-fyri-perilous-pursuit-0">
            <img className="kickstarter" src={kickstarter} alt="kickstarter logo" />
        </a>
        <p className="or">Or</p>
        <div className="form">
          <p>Email</p>
            <MailChimpForm/>
        </div>
        <p className="notis"> To be notified upon launch</p>
      </div>
    </div>
  );
};

export default Game;
