import React from "react";
import './contact.css'
import facebook_socials from '../../images/facebook_socials.png';
import instagram_socials from '../../images/instagram_socials.png';
import twitter_socials from '../../images/twitter_socials.png';
import youtube_socials from '../../images/youtube_socials.png';
import reddit_socials from '../../images/reddit_socials.png';
//import discord_socials from '../../images/discord_socials.png';
import boardGameGeek_socials from '../../images/BoardGameGeek_socials.png';

const Contact = () => {
  return(
    <div className='contact'>
      <div className="contact-details">
        <p>For direct inquires:</p>
        <p className="email">info@secretbootspublishing.com</p>
      <div className="socials-icons">
        <p>follow us on our socials:</p>
          <a href="https://www.facebook.com/secretbootspublishing">  
            <img className="icon" src={facebook_socials} alt="facebook icon" />
          </a>
          <a href="https://www.instagram.com/secretbootspublishing/">
            <img className="icon" src={instagram_socials} alt="instagram icon" />
          </a>
          <a href="https://twitter.com/sbpgames">
            <img className="icon" src={twitter_socials} alt="twitter icon" />
          </a>
          <a href="https://www.youtube.com/channel/UCyHeiYJppLJxWbqAqQuuhlg">
            <img className="icon" src={youtube_socials} alt="youtube icon" />
          </a>
          <a href="https://www.reddit.com/r/sbpublishing/">  
            <img className="icon" src={reddit_socials} alt="reddit icon" />
          </a>
            {/* <img className="icon" src={discord_socials} alt="discord icon" /> */}
          <a href="https://boardgamegeek.com/boardgame/371527/forest-fyri-perilous-pursuit">
            <img className="icon" src={boardGameGeek_socials} alt="boardgamesgeek icon" />
          </a>
        </div>
      </div>
    </div>
  );
};

export default Contact;