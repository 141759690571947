import React from "react";
import './Footer.css';
import facebook from '../images/facebook_socials.png';
import instagram from '../images/instagram_socials.png';
import twitter from '../images/twitter_socials.png';
import youtube from '../images/youtube_socials.png';
import reddit from '../images/reddit_socials.png';
// import discord from '../images/discord_socials.png';
import boardGameGeek from '../images/BoardGameGeek_socials.png';




const Footer = () => {
  return (
    <div className="footer">
      <div className="socialmedia-icons">
        <a href="https://www.facebook.com/secretbootspublishing">
          <img className="footer-icons" src={facebook} alt="Facebook logo" />
        </a>
        <a href="https://www.instagram.com/secretbootspublishing/">
          <img className="footer-icons" src={instagram} alt="Instagram logo" />
        </a>
        <a href="https://twitter.com/sbpgames">
          <img className="footer-icons" src={twitter} alt="Twitter logo" />
        </a>
        <a href="https://www.reddit.com/r/sbpublishing/">
          <img className="footer-icons" src={reddit} alt="reddit logo" />
        </a>
        <a href="https://boardgamegeek.com/boardgame/371527/forest-fyri-perilous-pursuit">
          <img className="footer-icons" src={boardGameGeek} alt="BoardGameGeek logo" />
        </a>
        <a href="https://www.youtube.com/channel/UCyHeiYJppLJxWbqAqQuuhlg">
          <img className="footer-icons" src={youtube} alt="youtube logo" />
        </a>
          {/* <img className="footer-icons" src={discord} alt="discord logo" /> */}
      </div>
      <p className="info">@2024 SB publishing AB, All Rights Reserved</p>
    </div>
  );
};
export default Footer;
