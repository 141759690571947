import React from 'react';
import Mailchimp from 'react-mailchimp-form';
import './mailchimpform.css';

const MailChimpForm = (props) => {
  return (
    <div>
      <Mailchimp
        action="https://theforestoffyri.us10.list-manage.com/subscribe/post?u=ed2bf0a1b84810887bd7f94ff&amp;id=8ea243f73a&amp;f_id=00be33e2f0"
        className="chimp-forms"
        fields={[
          {
            name: "EMAIL",
            placeholder: "email",
            type: "email",
            required: "true",
          }
        ]}
      />
    </div>
  );
};
export default MailChimpForm
