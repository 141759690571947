import React from "react";
import './Home.css';
import fyriLogo from '../../images/fyriLogo.png';
import game from '../../images/game.png'
import belt from '../../images/belt.png'
import kickstarter from '../../images/kickstarter.png'
import MailChimpForm from "components/MailchimpForm";

const Home = () => {
  return(
   <div className="home">
       <div className="hero">
        <img className="fyri-logo" src={fyriLogo} alt="Forest of Fyri logo" />
        <img className="game-img" src={game} alt="Game board" />
       </div>
       <div className="text-section-wrapper">
          <p className="home-text-section">
            The forest of full of <span className="emphasized-text">mythical creatures</span>. 
            Villagers share <span className="emphasized-text">cautionary tales</span> about them around the campfire, 
            mothers <span className="emphasized-text">scare</span> disobedient children with them, and the foolish souls
            that don't listen are <span className="emphasized-text">disappearing</span> one by one.
          </p>
          <p className="home-text-section">
            <span className="emphasized-text">6 strangers</span> have travelled from all <span className="emphasized-text">across</span> 
            the world to <span className="emphasized-text">hunt</span> these monsters
            and <span className="emphasized-text">prove their worth </span>
            to the town in the heart of the forest. 
            Survival in the woods is low - Unlike the rewards.
          </p>
          <p className="welcome-text">
            <span className="red-star">*</span> Welcome to the Forest of Fyri <span className="red-star">*</span>
          </p>
          <img className="belt" src={belt} alt="ornate belt" />
          <p className="sign-up">Sign up on</p>
            <a href="https://www.kickstarter.com/projects/sbpublishing/the-forest-of-fyri-perilous-pursuit-0">
              <img className="kickstarter" src={kickstarter} alt="kickstarter logo" />
            </a>
          <p className="or">Or</p>
          <div className="form">
            <p>Email</p>
            <MailChimpForm/>
          </div>
          <p className="notis"> To be notified upon launch</p>
        </div>
   </div>
  );
};

export default Home;
