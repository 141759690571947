import React from "react";
import './About.css';
import Town from '../../images/Town.png';
import fourway from '../../images/fourway.png';
const About = () => {
  return(
    <div className="about">
      <div className="tex-img-section">
        <img className="about-img" src={Town} alt="Mysterious Town" />
        <div className="text-section">
          <h1 className="heading">Secret Boots Publishing</h1>
          <p className="text-content">
            SB Publishing was born to represent our ultimate dream: 
            to help everyone realize they are nerds in their own way.
            Our goal is to create board games that can be played 
            by and with everyone regardless of age, generation, 
            gender, and culture. 
          </p>
          <p className="text-content">
            We ourselves come from different 
            background, with different life as well as gaming 
            experiences - but we have one thing in common: 
            we are proud nerds.
          </p>
        </div>
      </div>
      <div className="tex-img-section">
        <div className="text-section">
          <p className="text-content">
            We are creating games from our own experience of 
            inviting friends & family over for dinner, and playing 
            a board game over coffee and wine afterwards. 
          </p>
          <p className="text-content">
            We want everyone to be able to play with no commitment 
            or experience, while share a common interest in having 
            a good time. And if it helps with continuing a halted 
            conversation or create a conversation -or even better, 
            make the players show their competitive personality 
            that they didn’t know before, the board game has 
            fulfilled its purpose of creation. 
          </p>
        </div>
        <img className="about-img" src={fourway} alt="four way crossing"/>
      </div>
    </div>
  );
};

export default About;